import 'whatwg-fetch';
import * as moment from 'moment-timezone';
import * as querystring from 'querystring';

import { changeTimezone, isDateSelectFilter, transformDateFilters } from '../utils/transformDateFilters';
import { ActivityLogPageType } from './activityLogs/ActivityLogExport';
import { getProjectUrl } from './app/appConfig';
import {
    CustomEntityUpdateListType,
    EntityType,
    EntityUpdate,
    EntityUpdateRequest,
    ListedEntitiesType,
    TransactionRequest,
    ProductUpdateRequest,
    UpdateStateListType,
} from './authorization/taskAuthorizations';
import { ZeroingOption } from './beneficiaries/BeneficiaryActivitiesPage';
import { Beneficiary, BeneficiaryStatuses } from './beneficiaries/beneficiaries';
import { Location } from './beneficiaries/locations/locations';
import { Entitlement } from './beneficiaries/model';
import { EntityBalanceType } from './entityBalance/entityBalance';
import { Partner } from './partners/partners';
import { PaymentRequest, PaymentRequestStatus, SapPaymentRequest } from './paymentRequests/paymentRequests';
import { PermissionProfileStatus } from './permission-profiles/use-permission-profiles';
import { Outlet } from './product-management/types/outlet.type';
import { ParkProductPrice } from './product-management/types/park-product-price.type';
import { ProductResponseObject, ProductType } from './product-management/types/product.type';
import { Price, Product } from './products/helpers';
import { CreateTransactionRequestBody } from './transactions/adjustments/adjustments';
import { Transaction, TransactionChain, TransactionType } from './transactions/transactions';
import { Filter, FilterTypes } from './utils/FilterTypes';
import {
    AsyncTask,
    AsyncTaskScheduler,
    AsyncTasksTypes,
    SapPoToAmountEntry,
    SapPoToAmountRequestAction,
} from './utils/asyncTasks';
import { BigNumber } from './utils/bigNumber';
import { DIRECTION_OPTIONS, SORT_OPTIONS, SortOptions } from './utils/hooks/useSort';
import { PagedState } from './utils/paging';
import { UserEntityType } from './utils/users';
import { VendorBranch, VendorUserDraft } from './vendors/vendors';
import { windowStorage, WindowStorageToken } from './windowStorage';

export const tooLongValueError = new Error('Given value is too long');

export const BaseUrl = () => {
    return `${getProjectUrl()}api/manager`;
};

interface HasId {
    id: any;
}

function authHeaders() {
    return {
        Authorization: windowStorage.getItem(WindowStorageToken.managerApiToken) || '',
    };
}

function jsonHeaders() {
    return new Headers(
        Object.assign(
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            authHeaders()
        )
    );
}

function fetch(url, requestInit) {
    const invalid = false;
    // const body = requestInit.body ? JSON.parse(requestInit.body) : {}
    //
    // if(body) {
    //     Object.keys(body).forEach(key => {
    //         if (body[key] && body[key].length > 255) {
    //             throw tooLongValueError
    //         }
    //     })
    // }

    const query = url.split('?')[1] || '';
    const queryParams = querystring.parse(query);
    if (queryParams) {
        Object.keys(queryParams).forEach((key) => {
            if (queryParams[key] && queryParams[key].length > 255) {
                throw tooLongValueError;
            }
        });
    }

    const params = url.split('?')[0].split('/');
    params.forEach((param) => {
        if (param && param.length > 255) {
            throw tooLongValueError;
        }
    });

    return invalid
        ? null
        : window.fetch(url, requestInit).then((response) => {
              if (response.status < 200 || response.status >= 300) {
                  throw response;
              }
              if (response.status !== 204) {
                  return response.json();
              }
              return response;
          });
}

export function get(url): Promise<any> {
    return fetch(url, {
        method: 'GET',
        headers: jsonHeaders(),
    });
}

export function deleteRequest(url, body = {}): Promise<any> {
    return fetch(url, {
        method: 'DELETE',
        headers: jsonHeaders(),
        body: JSON.stringify(body),
    });
}

export function post(url, body): Promise<any> {
    return fetch(url, {
        method: 'POST',
        headers: jsonHeaders(),
        body: JSON.stringify(body),
    });
}

export function patch(url, body): Promise<any> {
    const jsonBody = JSON.stringify(body);
    return fetch(url, {
        method: 'PATCH',
        headers: jsonHeaders(),
        body: jsonBody,
    });
}

// function download(url, body): Promise<any> {
//     const requestInit = {
//         method: 'POST',
//         headers: jsonHeaders(),
//         body: JSON.stringify(body),
//     };
//
//     return window
//         .fetch(url, requestInit)
//         .then((response) => {
//             if (response.status !== 201) {
//                 throw response;
//             }
//
//             const location = response.headers.get('location');
//             if (!location) {
//                 throw new Error('File Location header missing.');
//             }
//             window.location.href = location;
//         })
//         .catch((e) => {
//             if (e instanceof TypeError && e.message === 'Failed to fetch') {
//                 throw new Error('Failed to fetch, request timeout');
//             }
//             throw e;
//         });
// }

export interface TransactionRequestParams {
    'createdAt:lte'?: string;
    'createdAt:gte'?: string;
    category?: string;
    vendorId?: string;
    status?: string;
    beneficiaryId?: string;
    paymentRequestId?: string;
    tab?: 'expenditures' | 'adjustments';
}

export function upload(url, data): Promise<any> {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
        let value = data[key];
        if (value && value.constructor && value.constructor.name === 'Object') {
            value = JSON.stringify(value);
        }

        formData.append(key, value);
    }
    const requestInit = {
        method: 'POST',
        headers: new Headers(
            Object.assign(
                {
                    Accept: 'application/json',
                },
                authHeaders()
            )
        ),
        body: formData,
    };

    return fetch(url, requestInit);
}

function assignFiltersToParams(filters: Array<Filter>, params: { _offset: number; _limit: number }) {
    filters.forEach((f) => {
        if (f.isSelected) {
            if (isDateSelectFilter(f)) {
                const dateFilter = f;
                const startDateString = moment(dateFilter.dateRange.startDate).tz('utc').format();
                const endDateString = moment(dateFilter.dateRange.endDate).tz('utc').format();
                params[f.name + ':gte'] = startDateString;
                params[f.name + ':lte'] = endDateString;
            } else if (f.type === FilterTypes.multiselect) {
                const values = f.value ? f.value.map((selectOption) => selectOption.value) : [];
                params[f.name] = values.toString();
            } else {
                params[f.name] = f.value;
            }
        }
    });
}

async function getWithOffset<T>(
    url: string,
    filters: Array<Filter>,
    offset: number,
    limit: number,
    customParams: any = {}
): Promise<PagedState<T>> {
    const params = Object.assign({}, { _offset: offset, _limit: limit });
    assignFiltersToParams(filters, params);
    Object.assign(params, customParams);
    const queryString = querystring.stringify(params);

    const res = await get(`${url}?${queryString}`);
    if (res.status === 204) {
        return new PagedState<T>().configureFor204Response(limit, filters);
    }
    return res;
}

export function getWithPaging<T>(
    url: string,
    filters: Array<Filter>,
    page: number,
    limit: number,
    customParams: any = {}
): Promise<PagedState<T>> {
    const offset = (page - 1) * limit;
    return getWithOffset(url, filters, offset, limit, customParams);
}

async function postWithOffset<T>(
    url: string,
    filters: Array<Filter>,
    offset: number,
    limit: number,
    customBody: any = {}
): Promise<PagedState<T>> {
    const body = Object.assign({}, { _offset: offset, _limit: limit });
    assignFiltersToParams(filters, body);
    Object.assign(body, customBody);

    const res = await post(url, body);
    if (res.status === 204) {
        return new PagedState<T>().configureFor204Response(limit, filters);
    }
    return res;
}

export function postWithPaging<T>(
    url: string,
    filters: Array<Filter>,
    page: number,
    limit: number,
    customParams: any = {}
): Promise<PagedState<T>> {
    const offset = (page - 1) * limit;
    return postWithOffset(url, filters, offset, limit, customParams);
}

/////// exported functions

export function healthCheck() {
    return get(`${BaseUrl()}/health-check`);
}

export function login(email: string, password: string, resetOtp: boolean, otp?: string, secret?: string) {
    const search = new URLSearchParams(window.location.search);
    const entityType = search.get('e');
    let params;
    if (entityType === UserEntityType.manager) {
        params = {
            managerId: atob(search.get('u')),
            token: search.get('t'),
        };
    }
    return post(`${BaseUrl()}/auth`, {
        email,
        password,
        resetOtp,
        otp,
        secret,
        ...params,
    });
}

export function getOtpData(userPassword: string) {
    return post(`${BaseUrl()}/otp-data`, {
        userPassword,
    });
}

export function getMyself() {
    return get(`${BaseUrl()}/myself`);
}

export function logout() {
    return post(`${BaseUrl()}/logout`, {});
}

export function newAuthToken() {
    return post(`${BaseUrl()}/new-auth-token`, {});
}

export function getPendingActions() {
    return get(`${BaseUrl()}/pending-requests`);
}

enum Entities {
    'm' = 'manager',
    'v' = 'vendor',
    'p' = 'partner',
}

export async function resetPassword(password: string, oldPassword?: string) {
    const search = new URLSearchParams(window.location.search);
    const entityType = Entities[search.get('e')];
    const params = {
        password,
        id: atob(search.get('u')),
        token: search.get('t'),
        otpToken: search.get('o'),
        oldPassword: oldPassword,
        oldPasswordRequired: search.get('op'),
    };
    return post(`${getProjectUrl()}api/${entityType}/reset-password`, params);
}

export function sessionInvalidation() {
    const search = new URLSearchParams(window.location.search);
    const entityType = search.get('e');
    if (entityType === UserEntityType.manager) {
        const params = {
            managerId: atob(search.get('u')),
            token: search.get('t'),
        };
        return post(`${BaseUrl()}/session-invalidation`, params);
    }
}

export function requestManagerPasswordReset(manager: HasId, transport: string) {
    return post(`${BaseUrl()}/managers/${manager.id}/resend-password`, {
        transport,
    }).then((data) => {
        return data.resetLink;
    });
}

export function changePassword(oldPassword: string, newPassword: string) {
    return post(`${BaseUrl()}/change-password`, { oldPassword, newPassword });
}

export function requestManagerOtpPasswordReset(manager: HasId, transport: string) {
    return post(`${BaseUrl()}/managers/${manager.id}/resend-otp-password`, {
        transport,
    }).then((data) => {
        return data.resetLink;
    });
}

export function requestManagerOtpReset(manager: HasId, transport: string) {
    return post(`${BaseUrl()}/managers/${manager.id}/resend-otp`, {
        transport,
    }).then((data) => {
        return data.resetLink;
    });
}

export function loadVendors(sortOptions: SORT_OPTIONS, filters?: Filter[]) {
    const _filters =
        filters &&
        filters
            .map((filter) => ({ [filter.name]: filter.value.map((val) => val.value) }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const queryString = new URLSearchParams({ ...sortOptions, ..._filters }).toString();

    return get(`${BaseUrl()}/vendors?${queryString}`);
}

export function loadVendorUsers(id: string, sortOptions: SORT_OPTIONS, filters?: Filter[]) {
    const _filters =
        filters &&
        filters
            .map((filter) => ({ [filter.name]: filter.value.map((val) => val.value) }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const queryString = new URLSearchParams({ ...sortOptions, ..._filters }).toString();

    return get(`${BaseUrl()}/vendor-users/${id}/list-vendor-users?${queryString}`);
}

export function loadProducts(query?: { filter: string }) {
    const queryString = query ? `?${new URLSearchParams(query)}` : '';
    return get(`${BaseUrl()}/products${queryString}`);
}

export function loadPagingProducts(
    page,
    limit,
    query?: {
        filter?: string;
        'orderBy:column'?: string;
        'orderBy:direction'?: DIRECTION_OPTIONS;
    }
) {
    return getWithPaging<Product>(`${BaseUrl()}/products/paging-list`, [], page, limit, query);
}

export function updateProductsForGroup(body) {
    return post(`${BaseUrl()}/products/prices`, body);
}

export function loadProduct(id: string) {
    return get(`${BaseUrl()}/products/${id}`);
}

export function loadProductVendors() {
    return get(`${BaseUrl()}/products/vendors`);
}

export function getVendorsWithProductPrices(productId) {
    return get(`${BaseUrl}/vendors/product-prices/${productId}`);
}

export function loadPrices() {
    return get(`${BaseUrl()}/products/prices`);
}

export function loadPricesForProduct(id: string) {
    return get(`${BaseUrl()}/products/${id}/prices`);
}

export function parkAndAuthorizeProduct(product: Partial<Product>) {
    return post(`${BaseUrl()}/products`, product);
}

export function parkAndAuthorizeProductPrice(productId: string, price: Partial<Price>) {
    return post(`${BaseUrl()}/products/${productId}/prices`, price);
}

export function updateAndAuthorizeProduct(product: Product) {
    return patch(`${BaseUrl()}/products/${product.id}`, product);
}

export function updateAndAuthorizeProductPrice(productId: string, price: Price) {
    return patch(`${BaseUrl()}/products/${productId}/prices/${price.id}`, price);
}

export function getVendorUser(id: string) {
    return get(`${BaseUrl()}/vendor-users/${id}`);
}

export function getVendorCount() {
    return get(`${BaseUrl()}/vendors/count`);
}

export function getVendorProducts(vendorId) {
    return get(`${BaseUrl()}/vendors/${vendorId}/products`);
}

export function createVendorUser(vendorUser: VendorUserDraft) {
    return post(`${BaseUrl()}/vendor-users/create`, { ...vendorUser });
}

export function authorizeVendorUser(vendorUserId: string) {
    return post(`${BaseUrl()}/vendor-users/${vendorUserId}/authorize`, {});
}

export function cancelVendorUserPark(vendorUserId: string) {
    return post(`${BaseUrl()}/vendor-users/${vendorUserId}/cancel-park`, {});
}

export function loadPartner(partnerId: string) {
    return get(`${BaseUrl()}/partners/${partnerId}`);
}

export function loadPartners(sortOptions: SORT_OPTIONS, filters?: Filter[]) {
    const _filters =
        filters &&
        filters
            .map((filter) => ({ [filter.name]: filter.value.map((val) => val.value) }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const queryString = new URLSearchParams({ ...sortOptions, ..._filters }).toString();

    return get(`${BaseUrl()}/partners?${queryString}`);
}

export function loadParkedPartnerUsers() {
    return get(`${BaseUrl()}/partner-users/parked`);
}

export function createPartner(partner: Partner) {
    return post(`${BaseUrl()}/partners`, partner);
}

export function cancelPartner(partnerId) {
    return post(`${BaseUrl()}/partners/${partnerId}/cancel-park`, {});
}

export function loadPartnersNames(sortOptions: SORT_OPTIONS) {
    const query = sortOptions && Object.values(sortOptions).every(Boolean) ? new URLSearchParams(sortOptions) : '';

    return get(`${BaseUrl()}/partners/names?${query}`);
}

export function loadPartnerUsersNames(sortOptions: SORT_OPTIONS) {
    const query = new URLSearchParams(sortOptions);
    return get(`${BaseUrl()}/partner-users/names?${query}`);
}

export function createPartnerUser(partnerUser) {
    partnerUser.validUntil = new Date(partnerUser.validUntil);
    return post(`${BaseUrl()}/partner-users`, partnerUser);
}

export function cancelPartnerUser(partnerUserId) {
    return post(`${BaseUrl()}/partner-users/${partnerUserId}/cancel-park`, {});
}

export function loadPartnerUsers(partnerId, sortOptions: SORT_OPTIONS, filters?: Filter[]) {
    const _filters =
        filters &&
        filters
            .map((filter) => ({ [filter.name]: filter.value.map((val) => val.value) }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const queryString = new URLSearchParams({ ...sortOptions, ..._filters }).toString();

    return get(`${BaseUrl()}/partner-users/${partnerId}?${queryString}`);
}

export function authorizePartnerUser(partnerUserId) {
    return post(`${BaseUrl()}/partner-users/${partnerUserId}/authorize`, {});
}

export function resetPartnerUserPassword(partnerUserId) {
    return post(`${BaseUrl()}/partner-users/${partnerUserId}/resend-password`, { transport: 'email' });
}

export function loadSinglePartnerUser(partnerUserId) {
    return get(`${BaseUrl()}/partner-users/${partnerUserId}/findOne`);
}

export function authorizePartner(id) {
    return post(`${BaseUrl()}/partners/${id}/authorize`, {});
}

export function loadVendorsNickNames(query: SORT_OPTIONS) {
    const searchparams = Object.entries(query).reduce(
        (object, [key, value]) =>
            value
                ? {
                      ...object,
                      [key]: value,
                  }
                : object,
        {}
    );
    const querystring = new URLSearchParams(searchparams);
    return get(`${BaseUrl()}/vendors/nick-names?${querystring}`);
}

export function loadVendorUsersNames(sortOptions: SORT_OPTIONS) {
    const query = sortOptions && Object.values(sortOptions).every(Boolean) ? new URLSearchParams(sortOptions) : '';
    return get(`${BaseUrl()}/vendor-users/names?${query}`);
}

export function loadVendor(vendorId: string) {
    return get(`${BaseUrl()}/vendors/${vendorId}`);
}

export function loadBranchCodes() {
    return get(`${BaseUrl()}/branch-codes`);
}

export function saveVendor(vendor: HasId, permissionsFile: File = null) {
    if (vendor.id) {
        return post(`${BaseUrl()}/entity-update-requests/vendor-details`, vendor)
            .then((res) => {
                return res.vendor;
            })
            .catch(() => {
                return 400;
            });
    } else {
        return upload(`${BaseUrl()}/vendors`, { ...vendor, file: permissionsFile })
            .then((res) => {
                return res.vendor;
            })
            .catch((err) => {
                return err.status;
            });
    }
}

export function savePartner(partner: HasId) {
    if (partner.id) {
        return post(`${BaseUrl()}/entity-update-requests/partner-details`, partner)
            .then((res) => {
                return res.partner;
            })
            .catch(() => {
                return 400;
            });
    } else {
        return post(`${BaseUrl()}/partners`, partner)
            .then((res) => {
                return res.partner;
            })
            .catch((err) => {
                return err.status;
            });
    }
}

export function savePartnerUser(partnerUser) {
    return post(`${BaseUrl()}/entity-update-requests/partner-user-details`, partnerUser);
}

export function saveVendorUser(vendorUser: HasId) {
    if (vendorUser.id) {
        return post(`${BaseUrl()}/entity-update-requests/vendor-user-details`, vendorUser)
            .then((res) => {
                return res.vendorUser;
            })
            .catch(() => {
                return 400;
            });
    }
}

export function authorizeVendor(vendorId: string) {
    return post(`${BaseUrl()}/vendors/${vendorId}/authorize`, {}).then((data) => data.vendor);
}

export function requestVendorUserPasswordReset(vendorUser: HasId, transport: string) {
    return post(`${BaseUrl()}/vendor-users/${vendorUser.id}/resend-password`, {
        transport,
    });
}

export function requestPartnerPasswordReset(partnerId: string, transport: string) {
    return post(`${BaseUrl()}/partners/${partnerId}/resend-password`, {
        transport,
    });
}

export function exportVendors(columns) {
    return post(`${BaseUrl()}/vendors/export`, {
        fileName: 'Vendors.xlsx',
        columns,
    });
}

export function importVendors(data) {
    return upload(`${BaseUrl()}/vendors/import`, data);
}

export function cancelVendorPark(id: string) {
    return post(`${BaseUrl()}/vendors/${id}/cancel-park`, {});
}

export function cancelManagerPark(id: string) {
    return post(`${BaseUrl()}/managers/${id}/cancel-park`, {});
}

export function uploadPermissionsFile(id: number | string, file: File) {
    return upload(`${BaseUrl()}/managers/${id}/permissions`, { file });
}

export function uploadVendorPermissionsFile(id: string, file: File) {
    return upload(`${BaseUrl()}/vendors/${id}/permissions`, { file });
}

export function uploadPartnerPermissionsFile(id: string, file: File) {
    return upload(`${BaseUrl()}/partners/${id}/permissions`, { file });
}

export function createPermissionProfile(newProfile: { name: string; permissions: string[] }) {
    return post(`${BaseUrl()}/permission-profiles`, newProfile);
}

export function rejectPermissionProfile(id: number) {
    return patch(`${BaseUrl()}/permission-profiles/${id}/reject`, {});
}

export function cancelPermissionProfile(id: number) {
    return deleteRequest(`${BaseUrl()}/permission-profiles/${id}`);
}

export function authorizePermissionProfile(id: number) {
    return patch(`${BaseUrl()}/permission-profiles/${id}/authorize`, {});
}

export function createPermissionProfileUpdate(profile) {
    return post(`${BaseUrl()}/entity-update-requests/permission-profile-details`, profile);
}

export function loadPermissionProfiles(sortOptions: SORT_OPTIONS, permissionProfilesStatus: PermissionProfileStatus) {
    const queryString = new URLSearchParams({ ...sortOptions, status: permissionProfilesStatus }).toString();

    return get(`${BaseUrl()}/permission-profiles?${queryString}`);
}

export function exportBeneficiaries(timezone: string, columns, filters) {
    const params = { _offset: 0, _limit: 0 };
    const _filters = filters.map(transformDateFilters(timezone));
    assignFiltersToParams(_filters, params);
    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/beneficiaries/export?${queryString}`, {
        fileName: 'Beneficiaries.xlsx',
        columns,
    });
}

export function resetBeneficiaryPin(beneficiaryId: string) {
    return post(`${BaseUrl()}/beneficiaries/reset-beneficiary-pin`, { beneficiaryId });
}

export function exportBeneficiaryPins(timezone: string, filters: Filter[]): Promise<AsyncTask> {
    const params = { _offset: 0, _limit: 0 };
    const _filters = filters.map(transformDateFilters(timezone));
    assignFiltersToParams(_filters, params);
    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/beneficiaries/export-pins?${queryString}`, {});
}

export function importBeneficiaryMapping(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/beneficiaries/import-beneficiary-mapping`, data);
}

export function importBeneficiaries(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/beneficiaries/import`, data);
}

export function importAlternativeCollectors(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/beneficiaries/import-alternative-collectors`, data);
}

export function removeAlternativeCollectors(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/beneficiaries/remove-alternative-collectors`, data);
}

export function blockBeneficiaries(data, isZeroingBalance?: boolean): Promise<AsyncTask> {
    if (isZeroingBalance) {
        return upload(`${BaseUrl()}/beneficiaries/block-and-zero`, data);
    } else {
        return upload(`${BaseUrl()}/beneficiaries/block`, data);
    }
}

export function zeroBeneficiaries(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/beneficiaries/zero`, data);
}

export function unblockBeneficiaries(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/beneficiaries/unblock`, data);
}

export function addAdditionalFile(data, taskType): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/async-task-additional-file/upload/${taskType}`, data);
}

export function loadBeneficiaries(
    timezone: string,
    filters: Array<Filter>,
    page: number,
    limit: number,
    sortOptions: SORT_OPTIONS
) {
    const _filters = filters.map(transformDateFilters(timezone));
    const sort = sortOptions && Object.values(sortOptions).every(Boolean) ? new URLSearchParams(sortOptions) : '';
    return postWithPaging<Beneficiary>(`${BaseUrl()}/beneficiaries/list?${sort}`, _filters, page, limit);
}

export function getAsyncTasksList(filters: Array<Filter>, page: number, limit: number, type = '') {
    return getWithPaging<AsyncTask>(`${BaseUrl()}/tasks/${type}`, filters, page, limit);
}

export function appendAsyncTasksList(filters: Array<Filter>, offset: number, limit: number, type = '') {
    return getWithOffset<AsyncTask>(`${BaseUrl()}/tasks/${type}`, filters, offset, limit);
}

export function getAsyncTask(type: string, id: string): Promise<{ task: AsyncTask; scheduler: AsyncTaskScheduler }> {
    return get(`${BaseUrl()}/tasks/${type}/${id}`);
}

export function getAsyncTaskSubtasksArray(type: string, id: string): Promise<AsyncTask[]> {
    return get(`${BaseUrl()}/tasks/subtasks/${id}/${type}`);
}

export function authorizeAsyncTask(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${type}/${id}/authorize`, {});
}

export function openFile(taskType: string, id: string) {
    return get(`${BaseUrl()}/tasks/${taskType}/${id}/open`);
}

export function downloadFile(taskType: string, id: string) {
    return get(`${BaseUrl()}/tasks/${taskType}/${id}/download`)
        .then((data) => data.url)
        .catch((err) => (err.status ? err.status : 500));
}

export function downloadSpecificFile(taskType: string, id: string, index: number) {
    return get(`${BaseUrl()}/tasks/${taskType}/${id}/${index}/download`)
        .then((data) => data.url)
        .catch((err) => (err.status ? err.status : 500));
}

export function downloadStatic(fileName: string) {
    return get(`${BaseUrl()}/static/${fileName}/download`)
        .then((data) => data.url)
        .catch((err) => (err.status ? err.status : 500));
}

export function downloadAdditionalFile(fileKey: string) {
    return post(`${BaseUrl()}/async-task-additional-file/url`, { fileKey })
        .then((data) => data.url)
        .catch((err) => (err.status ? err.status : 500));
}

export function cancelTask(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${type}/${id}/cancel`, {});
}

export function cancelWholeImport(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${type}/${id}/cancel-import`, {});
}

export function cancelSubTask(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${AsyncTasksTypes.IMPORT_BENEFICIARIES}/${id}/cancel-scheduled`, {});
}

export function cancelRunningExport(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${type}/${id}/cancel-running-export`, {});
}

export function getImportTaskPoDetails() {
    return get(`${BaseUrl()}/tasks/beneficiary-import-requests/po-details`);
}

export function manualSapPoToAmountPost(
    asyncTaskId: string,
    sapPoToAmountEntries: Array<SapPoToAmountEntry>,
    action: SapPoToAmountRequestAction
) {
    if (action === SapPoToAmountRequestAction.park) {
        return post(`${BaseUrl()}/tasks/${AsyncTasksTypes.IMPORT_BENEFICIARIES}/${asyncTaskId}/manual-po`, {
            sapPoToAmountEntries,
        });
    } else if (action === SapPoToAmountRequestAction.post) {
        return post(`${BaseUrl()}/tasks/${AsyncTasksTypes.IMPORT_BENEFICIARIES}/${asyncTaskId}/manual-po/authorize`, {
            sapPoToAmountEntries,
        });
    } else if (action === SapPoToAmountRequestAction.cancel) {
        return post(`${BaseUrl()}/tasks/${AsyncTasksTypes.IMPORT_BENEFICIARIES}/${asyncTaskId}/manual-po/cancel`, {
            sapPoToAmountEntries,
        });
    }
}

export function createBeneficiary(beneficiary: HasId) {
    return post(`${BaseUrl()}/beneficiaries`, beneficiary);
}

export function editBeneficiary(beneficiary) {
    return post(`${BaseUrl()}/entity-update-requests/beneficiary-details`, beneficiary);
}

export function addEntitlements(beneficiaryId: string, entitlements: Array<Entitlement>) {
    return post(`${BaseUrl()}/beneficiaries/${beneficiaryId}/entitlements`, {
        entitlements,
    });
}

export async function getEntitlements(beneficiaryId: string, pageNumber: number, pageSize: number) {
    const url = `${BaseUrl()}/beneficiaries/beneficiary/entitlements`;
    const response = await postWithPaging<Entitlement>(url, [], pageNumber, pageSize, { beneficiaryId });
    return response;
}

export async function getClustersInfo(beneficiaryId: string) {
    const url = `${BaseUrl()}/beneficiaries/beneficiary/token-clusters`;
    return post(url, { beneficiaryId });
}

export async function loadBeneficiary(beneficiaryId: string) {
    const response = await post(`${BaseUrl()}/beneficiaries/beneficiary`, {
        beneficiaryId,
    });
    return response.beneficiary;
}

export function loadTransactions(
    timezone: string,
    filters: Array<Filter> = [],
    page: number,
    limit: number,
    customParams: TransactionRequestParams = {},
    sortOptions: SORT_OPTIONS
): Promise<PagedState<Transaction>> {
    const query = sortOptions?.['orderBy:column'] ? new URLSearchParams(sortOptions) : '';
    const _filters = filters.map(transformDateFilters(timezone));
    const paged = postWithPaging<Transaction>(
        `${BaseUrl()}/transactions/list?${query}`,
        _filters,
        page,
        limit,
        customParams
    );
    return paged.then((result) => {
        result.items.forEach((transaction) => (transaction.amount = new BigNumber(transaction.amount)));
        return result;
    });
}

export function loadTransactionChain(transactionId: string): Promise<TransactionChain> {
    return get(`${BaseUrl()}/transactions/${transactionId}`).then((result) => {
        const chain: TransactionChain = result;
        chain.amount = new BigNumber(chain.amount);
        chain.items.forEach((transaction) => {
            transaction.amount = new BigNumber(transaction.amount);
        });
        return result;
    });
}

export function loadTransactionDetails(transactionId: string) {
    return get(`${BaseUrl()}/transactions/${transactionId}/details`);
}

export function getLastTransactions(limit: number) {
    return get(`${BaseUrl()}/live/transactions?limit=${limit}`);
}

export function getTransactionTypeTotals(
    timezone: string,
    startDate?: string,
    endDate?: string,
    types?: Array<TransactionType>
) {
    let customParam = {
        startDate: startDate ? changeTimezone(new Date(startDate.slice(0, 19)), timezone).toISOString() : undefined,
        endDate: endDate ? changeTimezone(new Date(endDate.slice(0, 19)), timezone).toISOString() : undefined,
    };

    if (types) {
        customParam = Object.assign({}, customParam, { type: types });
    }
    const queryString = querystring.stringify(customParam);
    const tot = get(`${BaseUrl()}/transactions/type-totals/?${queryString}`);
    return tot;
}

export function getTokensStatistics() {
    return get(`${BaseUrl()}/transactions/tokens-statistics`);
}

export function exportTransactions(timezone: string, columns, filters, customParams: TransactionRequestParams = {}) {
    const params = { _offset: 0, _limit: 0 };
    const _filters = filters.map(transformDateFilters(timezone));
    assignFiltersToParams(_filters, params);
    Object.assign(params, customParams);
    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/transactions/export?${queryString}`, {
        fileName: 'Transactions.xlsx',
        columns,
    });
}

export function loadPaymentRequests(
    periodEnd: Date,
    category: string,
    filters: Array<Filter>,
    page: number,
    limit: number
) {
    return getWithPaging<PaymentRequest>(`${BaseUrl()}/payment-requests`, filters, page, limit, {
        periodEnd: periodEnd.toISOString(),
        category: category,
    });
}

export function loadPaymentPendingActions(category: string, filters: Array<Filter>, page: number, limit: number) {
    return getWithPaging<PaymentRequest>(`${BaseUrl()}/pending-payment`, filters, page, limit, {
        category: category,
    });
}

export function loadPaymentRequest(paymentRequestId: string) {
    return get(`${BaseUrl()}/payment-requests/${paymentRequestId}`);
}

export function loadPaymentPeriods(page, limit) {
    return getWithPaging(`${BaseUrl()}/payment-requests/periods`, [], page, limit);
}

export function getPossibleTargetStatuses(paymentRequestId: string) {
    return get(`${BaseUrl()}/payment-requests/${paymentRequestId}/target-status`);
}

export function getPaymentSapPoDetails(paymentRequestId: string) {
    return get(`${BaseUrl()}/payment-requests/${paymentRequestId}/po-details`);
}

export function changePaymentRequestStatus(
    paymentRequestId: string,
    targetStatus: PaymentRequestStatus,
    routeOverride?: string,
    paymentSapDetails?: Array<SapPaymentRequest>
) {
    return post(`${BaseUrl()}/payment-requests/${paymentRequestId}/change-status/${targetStatus}`, {
        paymentSapDetails,
    });
}

export function getSecurityData() {
    return get(`${BaseUrl()}/security`);
}

export function postOtpSecret(otp: string, otpSecret: string, managerId: number, oldOtp?: string) {
    return post(`${BaseUrl()}/security/otp`, { otp, otpSecret, managerId, oldOtp });
}

export function createOtpSecret(userPassword: string) {
    return post(`${BaseUrl()}/security/otp-request`, { userPassword });
}

export function deleteOtpSecret(userPassword: string) {
    return deleteRequest(`${BaseUrl()}/security/otp`, { userPassword });
}

export function sendOtpCode(email: string, password: string) {
    return post(`${BaseUrl()}/send-otp-email`, { email, password });
}

export function addBeneficiaryComment(beneficiaryId: string, comment: string) {
    return post(`${BaseUrl()}/beneficiaries/comments`, {
        beneficiaryId,
        comment,
    });
}

export function updateBeneficiaryStatus(
    beneficiaryId: string,
    status: BeneficiaryStatuses,
    reason: string,
    source: string
) {
    const action = status === BeneficiaryStatuses.active ? 'unblock' : 'block';
    return post(`${BaseUrl()}/entity-update-requests/beneficiary-${action}`, {
        id: beneficiaryId,
        reason,
        source,
    });
}

export function zeroBeneficiary(beneficiaryId: string, reason: string, source: string, zeroingOption: ZeroingOption) {
    return post(`${BaseUrl()}/entity-update-requests/beneficiary-zeroing`, {
        id: beneficiaryId,
        zeroingReason: reason,
        zeroingSource: source,
        categories: zeroingOption.category,
        availabilityType: zeroingOption.availabilityType,
    });
}

export function acStatusChange(id: string, reason: string, source: string, newStatus: string) {
    return post(`${BaseUrl()}/entity-update-requests/alternative-collector-status-change`, {
        id: id,
        reason: reason,
        source: source,
        newStatus: newStatus,
    });
}

export function getManagers(sortingColumn = 'firstName', sortingOrder = 'ASC', filters = []) {
    const _filters = filters
        .map((filter) => ({ [filter.name]: filter.value.map((val) => val.value) }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    const queryString = new URLSearchParams({ sortingColumn, sortingOrder, ..._filters }).toString();
    return get(`${BaseUrl()}/managers?${queryString}`);
}

export function getManagersNickNames() {
    return get(`${BaseUrl()}/managers/nick-names`);
}

export function getManager(id: string) {
    return get(`${BaseUrl()}/managers/${id}`);
}

export async function editManager(manager: any) {
    return await post(`${BaseUrl()}/entity-update-requests/manager-details`, manager);
}

export function managerOtpChangeStatus(manager: any) {
    return post(`${BaseUrl()}/entity-update-requests/manager-details-otp`, manager);
}

export function editPersonalData(manager: any) {
    return patch(`${BaseUrl()}/managers/${manager.id}`, manager);
}

export function createManager(manager: any) {
    return post(`${BaseUrl()}/managers`, manager);
}

export function authorizeManager(id: string) {
    return post(`${BaseUrl()}/managers/${id}/authorize`, {});
}

export function getBasicActivityLog(managerId: string) {
    return get(`${BaseUrl()}/activity-logs/${managerId}/basic`).then((res) => res);
}

export function exportUsers(columns, id) {
    const params = { _offset: 0, _limit: 0 };
    if (id) {
        const idFilter = {
            name: 'id',
            type: FilterTypes.text,
            isSelected: true,
            value: id,
        };
        assignFiltersToParams([idFilter], params);
    }

    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/managers/export?${queryString}`, {
        columns,
    });
}

function getParamsForUpdateList(listType: UpdateStateListType) {
    const params = {
        type: listType.toLowerCase(),
    };
    switch (listType) {
        case UpdateStateListType.Requested:
            params['authorizedAt:eq'] = 'null';
            params['deletedAt:eq'] = 'null';
            return params;
        case UpdateStateListType.Posted:
            params['authorizedAt:ne'] = 'null';
            return params;
        case UpdateStateListType.Rejected:
            params['deletedAt:ne'] = 'null';
            return params;
    }

    return params;
}

export function createTransactionRequest(transactionRequest: CreateTransactionRequestBody) {
    return post(`${BaseUrl()}/transaction-requests`, transactionRequest);
}

export function getTransactionRequestsToAuthorize(listType: UpdateStateListType, page: number, limit: number) {
    const url = `${BaseUrl()}/transaction-requests`;
    const params = getParamsForUpdateList(listType);
    params['authorizationType'] = 'manager';
    return getWithPaging<TransactionRequest>(url, [], page, limit, params);
}

export function authorizeTransactionRequest(transactionRequestId: string) {
    return post(`${BaseUrl()}/transaction-requests/${transactionRequestId}/authorize`, {});
}

export function rejectTransactionRequest(transactionRequestId: string, comment: string) {
    return deleteRequest(`${BaseUrl()}/transaction-requests/${transactionRequestId}`, { comment });
}

export function getLastEntityBalance(entityId: string, entityType: EntityBalanceType) {
    return post(`${BaseUrl()}/entity-balance/last-balance/${entityType}`, {
        entityId,
    });
}

export function getEntityBalanceComparison(entityId: string, entityType: EntityBalanceType) {
    return post(`${BaseUrl()}/entity-balance/balance-comparison/${entityType}`, {
        entityId,
    });
}

export function editEntityUpdateRequest(id: string, newUpdates: EntityUpdate[]) {
    return patch(`${BaseUrl()}/entity-update-requests/pre-parked-beneficiaries/${id}`, newUpdates);
}

function requestPrefixForEntityUpdateRequestGET(listedEntitiesType: ListedEntitiesType) {
    switch (listedEntitiesType) {
        case CustomEntityUpdateListType.beneficiaryAllUpdates:
            return 'beneficiaries';
        case EntityType.vendorDetails:
            return 'vendor';
        case EntityType.managerDetails:
            return 'manager';
        case EntityType.preParkedBeneficiary:
            return 'pre-parked-beneficiary';
        case EntityType.vendorUserDetails:
            return 'vendor-user';
        case EntityType.partnerDetails:
            return 'partner';
        case EntityType.partnerUserDetails:
            return 'partner-user';
        case EntityType.locationDetails:
            return 'locations/get';
        case EntityType.productManagement:
            return 'product-management';
        case EntityType.permissionProfileDetails:
            return 'permission-profile';
    }
    return '';
}

export async function getEntityUpdateRequests(
    listedEntitiesType: ListedEntitiesType,
    requestsType: UpdateStateListType,
    page: number,
    limit: number,
    filters?: Filter[]
) {
    if (listedEntitiesType === CustomEntityUpdateListType.beneficiaryAllUpdates) {
        const url = `${BaseUrl()}/entity-update-requests/beneficiaries`;
        const params = getParamsForUpdateList(requestsType);
        return postWithPaging<EntityUpdateRequest>(url, filters || [], page, limit, params);
    } else {
        const prefix = requestPrefixForEntityUpdateRequestGET(listedEntitiesType);
        const url = `${BaseUrl()}/entity-update-requests/${prefix}`;
        const params = getParamsForUpdateList(requestsType);
        return postWithPaging<EntityUpdateRequest | ProductUpdateRequest>(url, filters || [], page, limit, params);
    }
}

function requestPrefixForEntityUpdateRequestChange(entityUpdateRequest: EntityUpdateRequest) {
    switch (entityUpdateRequest.entityType) {
        case EntityType.beneficiaryDetails:
            return 'beneficiary-details';
        case EntityType.beneficiarySingleBlock:
            return 'beneficiary-block';
        case EntityType.beneficiarySingleUnblock:
            return 'beneficiary-unblock';
        case EntityType.beneficiaryZeroing:
            return 'beneficiary-zeroing';
        case EntityType.vendorDetails:
            return 'vendor-details';
        case EntityType.managerDetails:
            return 'manager-details';
        case EntityType.preParkedBeneficiary:
            return 'pre-parked-beneficiaries';
        case EntityType.alternativeCollectorStatusChange:
            return 'alternative-collector-status-change';
        case EntityType.vendorUserDetails:
            return 'vendor-user-details';
        case EntityType.partnerDetails:
            return 'partner-details';
        case EntityType.partnerUserDetails:
            return 'partner-user-details';
        case EntityType.locationDetails:
            return 'locations';
        case EntityType.permissionProfileDetails:
            return 'permission-profile-details';
        case EntityType.productManagement:
            return 'product-management-details';
    }
    return '';
}

export function parkEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest) {
    const prefix = requestPrefixForEntityUpdateRequestChange(entityUpdateRequest);
    return post(`${BaseUrl()}/entity-update-requests/${prefix}/${entityUpdateRequest.id}/park`, {});
}

export function authorizeEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest) {
    const prefix = requestPrefixForEntityUpdateRequestChange(entityUpdateRequest);
    return post(`${BaseUrl()}/entity-update-requests/${prefix}/${entityUpdateRequest.id}/authorize`, {});
}

export function signEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest) {
    const prefix = requestPrefixForEntityUpdateRequestChange(entityUpdateRequest);
    return post(`${BaseUrl()}/entity-update-requests/${prefix}/${entityUpdateRequest.id}/sign`, {});
}

export function authorizeEntityUpdateRequestWithOtp(entityUpdateRequest: EntityUpdateRequest) {
    return post(`${BaseUrl()}/entity-update-requests/manager-details-otp/${entityUpdateRequest.id}/authorize`, {});
}

export function rejectEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest, comment: string) {
    const prefix = requestPrefixForEntityUpdateRequestChange(entityUpdateRequest);
    return deleteRequest(`${BaseUrl()}/entity-update-requests/${prefix}/${entityUpdateRequest.id}`, { comment });
}

export function exportActivityLog(entityId: string, entityType: ActivityLogPageType, options: any, filter) {
    const params = { _offset: 0, _limit: 0 };
    assignFiltersToParams([filter], params);
    const queryString = querystring.stringify(params);
    return post(`${BaseUrl()}/activity-logs/${entityType}/export?${queryString}`, { options, entityId });
}

export function getActivityLog(id: string) {
    return get(`${BaseUrl()}/activity-logs/${id}`);
}

export function loadAllActivityLogs(timezone: string, page: number, limit: number, filters: Filter[] = []) {
    const _filters = filters.map(transformDateFilters(timezone));
    const url = `${BaseUrl()}/activity-logs/list`;
    return postWithPaging(url, _filters, page, limit);
}

export function exportOversights(columns, filters) {
    const params = { _offset: 0, _limit: 0 };
    assignFiltersToParams(filters, params);
    const queryString = querystring.stringify(params);
    return post(`${BaseUrl()}/activity-logs/export?${queryString}`, {
        fileName: 'Oversight.xlsx',
        columns,
    });
}

export function loadActivityLogsChild(parentId: string, page: number, limit: number, filters: Filter[] = []) {
    const url = `${BaseUrl()}/activity-logs/list/${parentId}`;
    return getWithPaging(url, filters, page, limit);
}

export function getManagerActivityLogs(managerId: string, page: number, limit: number, filters: Filter[] = []) {
    const url = `${BaseUrl()}/activity-logs/manager/${managerId}`;
    return getWithPaging(url, filters, page, limit);
}

export function getBeneficiaryActivityLogs(beneficiaryId: string, page: number, limit: number, filters: Filter[] = []) {
    const url = `${BaseUrl()}/activity-logs/beneficiary`;
    return postWithPaging(url, filters, page, limit, { beneficiaryId });
}

export function getVendorActivityLogs(vendorId: string, page: number, limit: number, filters: Filter[] = []) {
    const url = `${BaseUrl()}/activity-logs/vendor/${vendorId}`;
    return getWithPaging(url, filters, page, limit);
}

export function getPartnerActivityLogs(partnerId: string, page: number, limit: number, filters: Filter[] = []) {
    const url = `${BaseUrl()}/activity-logs/partner/${partnerId}`;
    return getWithPaging(url, filters, page, limit);
}

export function sign(signatureId: string, managerId: string) {
    return post(`${BaseUrl()}/signatures/${signatureId}/sign`, {
        managerId: Number(managerId),
    });
}

export function getAppConfig() {
    return get(`${BaseUrl()}/app-config`);
}

export function loadLocations() {
    return get(`${BaseUrl()}/locations`);
}

export function loadLocationById(locationId) {
    return get(`${BaseUrl()}/locations/${locationId}`);
}

export function createLocation(data: { location1: string; location2: string }) {
    return post(`${BaseUrl()}/locations`, data);
}

export function authorizeLocation(locationId) {
    return patch(`${BaseUrl()}/locations/${locationId}/authorize`, {});
}

export function cancelLocation(locationId) {
    return patch(`${BaseUrl()}/locations/${locationId}/cancel`, {});
}

export function updateLocation(location: Location) {
    return post(`${BaseUrl()}/entity-update-requests/locations`, location);
}

export function getPermissionsList() {
    return get(`${BaseUrl()}/managers/permissions-list`);
}

export function getMobilizationStatistics(filters) {
    const query = new URLSearchParams(filters).toString();
    return get(`${BaseUrl()}/statistics/mobilization-statistics?${query}`);
}

export function getTodayMobilizationStatistics(filters) {
    const query = new URLSearchParams(filters).toString();
    return get(`${BaseUrl()}/statistics/mobilization-statistics/today?${query}`);
}

export function getMobilizationStatisticsSyncDate() {
    return get(`${BaseUrl()}/statistics/mobilization-statistics/sync-date`);
}

export async function loadDocuments() {
    return get(`${BaseUrl()}/documents`);
}

export async function uploadDocument(document) {
    return upload(`${BaseUrl()}/documents`, { ...document });
}

export async function loadDocumentSections() {
    return get(`${BaseUrl()}/documents/sections`);
}

export async function updateDocumentSectionName(oldSection, newSection) {
    return patch(`${BaseUrl()}/documents/section`, {
        oldSection,
        newSection,
    });
}

export async function updateDocumentFileName(documentId, oldName, newName) {
    return patch(`${BaseUrl()}/documents/${documentId}`, { oldName, newName });
}

export async function removeDocument(documentId) {
    return deleteRequest(`${BaseUrl()}/documents/${documentId}`);
}

export async function documentViewed(documentId) {
    return patch(`${BaseUrl()}/documents/${documentId}/mark-as-viewed`, {});
}

export async function updateSectionOrder(newOrder) {
    return patch(`${BaseUrl()}/documents/sections`, newOrder);
}

export async function loadProductGroups(sortOptions?: SORT_OPTIONS) {
    const queryString = querystring.stringify(sortOptions);
    return get(`${BaseUrl()}/product-group?${queryString}`);
}

export async function loadProductUnits() {
    return get(`${BaseUrl()}/products/units`);
}

export async function loadProductQuantityOptions() {
    return get(`${BaseUrl()}/products/quantity-options`);
}

export async function deleteProductPriceForVendor(productId, productPriceId) {
    return deleteRequest(`${BaseUrl()}/products/${productId}/prices/${productPriceId}`);
}

export function loadBranches(vendorId): Promise<VendorBranch[]> {
    return get(`${BaseUrl()}/branch-codes/vendor/${vendorId}`);
}

export function parkBranch(vendorId: string, outlet: string) {
    return post(`${BaseUrl()}/branch-codes/park`, { vendorId, outlet });
}

export function authorizeBranch(vendorId: string, branchId: string) {
    return post(`${BaseUrl()}/branch-codes/authorize`, { vendorId, id: branchId });
}

export function rejectBranch(vendorId: string, branchId: string) {
    return post(`${BaseUrl()}/branch-codes/reject`, { vendorId, id: branchId });
}

export function cancelBranch(vendorId: string, branchId: string) {
    return post(`${BaseUrl()}/branch-codes/cancel`, { vendorId, id: branchId });
}

export async function getGroupedProductPrices(sortOptions?: SORT_OPTIONS, filters?: Record<string, string[]>) {
    const query = sortOptions ? new URLSearchParams({ ...sortOptions, ...filters } as any) : '';
    return get(`${BaseUrl()}/product-price?${query}`);
}

export async function getBranchCodesMappedToVendors() {
    return get(`${BaseUrl()}/branch-codes/vendors`);
}

export async function getAllGroups() {
    return get(`${BaseUrl()}/product-group/all`);
}

export async function getCategories() {
    return get(`${BaseUrl()}/products/categories`);
}

export async function parkProductPrice(body: ParkProductPrice) {
    // return post(`${BaseUrl()}/product-price/park`, body);
    return null;
}

export async function authorizeProductPrice(priceId: number) {
    // return post(`${BaseUrl()}/product-price/authorize`, { priceId });
    return null;
}

export async function cancelProductPrice(priceId: number) {
    //     return patch(`${BaseUrl()}/product-price/cancel`, { priceId });
    return null;
}

export async function getProductPriceById(priceId: number | string) {
    // return get(`${BaseUrl()}/product-price/${priceId}`);
    return null;
}

export async function getAvailableOutletsForProduct(data: {
    productId: number;
    validFrom: Date;
    expiry: Date;
    priceId?: number;
}) {
    const query = new URLSearchParams({
        ...data,
        validFrom: moment(data.validFrom).format('yyyy-MM-DD'),
        expiry: moment(data.expiry).format('yyyy-MM-DD'),
    } as any);
    return get(`${BaseUrl()}/product-price/available-outlets?${query}`);
}

export async function getAllProductGroups() {
    return get(`${BaseUrl()}/product-group/get-all-groups`);
}

export async function findOutletsByGroupId(
    groupId: number | string,
    validFrom: Date = new Date(),
    sortOptions?: SortOptions
): Promise<Outlet[]> {
    const searchParams = (sortOptions ? { validFrom, ...sortOptions } : { validFrom: validFrom }) as any;
    const query = new URLSearchParams(searchParams);
    return get(`${BaseUrl()}/product-management/${groupId}/outlets?${query}`);
}

export async function findProductsByGroupId(
    groupId: number | string,
    validFrom: Date = new Date(),
    sortOptions?: SortOptions
): Promise<ProductResponseObject[]> {
    const searchParams = sortOptions ? { validFrom, ...sortOptions } : ({ validFrom: validFrom } as any);
    const query = new URLSearchParams(searchParams);
    return get(`${BaseUrl()}/product-management/${groupId}/products?${query}`);
}

export async function checkProductGroupPendingUpdateRequests(groupId: number | string): Promise<boolean> {
    return get(`${BaseUrl()}/product-management/${groupId}/pending-update-requests`);
}

export async function createProductGroupUpdateRequest(update: {
    productGroup: number;
    products: Partial<ProductType>[];
}) {
    return post(`${BaseUrl()}/entity-update-requests/product-management-details`, update);
}

export async function getProductGroupUpdateRequests() {
    return post(`${BaseUrl()}/entity-update-requests/product-group`, {});
}

export async function getBeneficiaryProductRestrictions(beneficiaryId: string) {
    return get(`${BaseUrl()}/product-limitation/${beneficiaryId}/restriction`);
}

export async function getBeneficiaryFinancialLimitations(beneficiaryId: string) {
    return get(`${BaseUrl()}/product-limitation/${beneficiaryId}/financial`);
}

export function loadUserStatuses() {
    return get(`${BaseUrl()}/managers/user-statuses`);
}

export async function getAllUIGroups() {
    return get(`${BaseUrl()}/product-group/get-all-ui-groups`);
}

export async function getUIGroupProducts(groupId: number, sortOptions?: SORT_OPTIONS) {
    const query = sortOptions
        ? new URLSearchParams(sortOptions)
        : new URLSearchParams({ column: 'name', direction: 'ASC' });
    return get(`${BaseUrl()}/products/group-products/${groupId}?${query}`);
}
