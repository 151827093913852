import moment = require('moment');

import { BigNumber } from './bigNumber';
import { PredefinedCurrency } from '../app/appConfig';
import { Moment } from 'moment-timezone';

export function displayMomentAsDay(date: Moment) {
    if (!date || !date.isValid()) {
        return '-';
    }

    return moment(date).format('YYYY-MM-DD');
}

export function displayAsDay(date: Date | string) {
    if (!date) {
        return '-';
    }

    return moment.utc(date).format('YYYY-MM-DD');
}

export function displayTime(date: Date | string) {
    if (!date) {
        return '-';
    }

    return moment.utc(date).format('YYYY-MM-DD HH:mm:ss UTC');
}

export function currencyFormatter(currency: PredefinedCurrency): Intl.NumberFormat {
    const intl = new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: currency.fractionDigits,
    });

    return {
        formatRange(startDate: number | bigint, endDate: number | bigint): string {
            throw new Error('Method is not supported' + startDate + ' ' + endDate);
        }, formatRangeToParts(startDate: number | bigint, endDate: number | bigint): Intl.NumberRangeFormatPart[] {
            throw new Error('Method is not supported' + startDate + ' ' + endDate);
        },
        format(value: number | bigint): string {
            const bigNumberValue = new BigNumber(value.toString(10));
            if (bigNumberValue.isNaN()) {
                return `${currency.name} ${intl.format(0)}`;
            } else {
                return `${currency.name} ${intl.format(bigNumberValue.toNumber())}`;
            }
        },
        resolvedOptions: intl.resolvedOptions,
        formatToParts: null
    };
}

export function hasOnlyWhitespaces(text: string) {
    return !text.replace(/\s/g, '').length;
}

export function isValidDate(dateString) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) {
        return false;
    } // Invalid format
    const d = new Date(dateString);
    if (!d.getTime() && d.getTime() !== 0) {
        return false;
    } // Invalid date
    return d.toISOString().slice(0, 10) === dateString;
}

export const supStyle = {
    backgroundColor: 'red',
    padding: 1,
    paddingLeft: 4,
    paddingRight: 4,
    color: 'white',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
};

export const isTimeDiffMoreThanYear = (startDate: Date, endDate: Date) => {
    const timeRangeInYears: number = moment(endDate).diff(moment(startDate), 'years');
    const isExceedingTimeRange: boolean = timeRangeInYears >= 1;
    return isExceedingTimeRange;
};

export function getCrumbsFromPath(path: string): Array<{ label: string; href: string }> {
    return removeTrailingSlash(path).split('/').map(createCrumb);
}

export function createCrumb(path, index, array) {
    const label = path.length >= 1 ? capitalize(path) : 'Home';
    const href = [...array].splice(0, index + 1).join('/');
    return { label, href };
}

export function removeTrailingSlash(path: string): string {
    return path.endsWith('/') ? path.slice(0, -1) : path;
}

export function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function split(separator: string) {
    return function (string: string): string[] {
        if (typeof string !== 'string') return string;
        return string.split(separator);
    };
}

function join(separator: string) {
    return function (array): string {
        return array.join(separator);
    };
}

function map(transform) {
    return function (array) {
        return array.map(transform);
    };
}

export function compose<T, K>(...fns: Array<(...args: any[]) => any>) {
    return function composed(initialValue?: T): K {
        return fns.reduceRight((value, fn) => fn(value), initialValue);
    };
}

export const capitalizeFirstLetter = (s: string): string => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function debounce(fn, ms) {
    let id;
    return function debounced(...args) {
        clearTimeout(id);
        id = setTimeout(fn, ms, ...args);
    };
}

export function separateCamelCase(string: string): string {
    const regex = new RegExp(/([a-z])([A-Z])/, 'g');
    return string.replace(regex, '$1 $2').toLowerCase();
}

export function removePrefix(prefix: string, string?: string) {
    function curried(str) {
        return str.toLowerCase().startsWith(prefix.toLowerCase()) ? str.slice(prefix.length).trim() : str;
    }

    return string ? curried(string) : curried;
}

function checkUpperCase(str: string): string {
    const alwaysUpperCase = ['oid', 'id', 'wfp', 'unicef', 'unfpa', 'tx', 'otp', 'mfa'];
    return alwaysUpperCase.includes(str.toLowerCase()) ? str.toUpperCase() : str;
}

function checkLowerCase(str: string): string {
    const alwaysLowerCase = ['at', 'to', 'in', 'by', 'out', 'and'];
    return alwaysLowerCase.includes(str.toLowerCase()) ? str.toLowerCase() : str;
}

export function wfpFormat(string: string): string {
    if (typeof string !== 'string') return string;
    return compose<string, string>(
        join(' '),
        map(checkLowerCase),
        map(checkUpperCase),
        map(capitalizeFirstLetter),
        split(' ')
    )(string);
}

export function splitSubstring(substring = 'otp', string?: string) {
    function curried(str) {
        const regex = new RegExp(`(\\w*)(${substring})(\\w*)`, 'gim');
        return str.replace(regex, `$1 $2 $3`).replace(/\s{2,}/gim, ' ');
    }

    return string ? curried(string) : curried;
}

export function unpick(object, ...keys: string[]): Record<string, unknown> {
    return Object.keys(object).reduce(
        (obj, key) =>
            !~keys.indexOf(key)
                ? {
                      ...obj,
                      [key]: object[key],
                  }
                : obj,
        {}
    );
}

export function flipCurried(fn) {
    return (arg2) => (arg1) => fn(arg1)(arg2);
}

export function flip(fn) {
    return (arg2) => (arg1) => fn(arg1, arg2);
}

export function throttle(ms, fn) {
    let id;
    return function throttled(...args) {
        if (id) return;
        id = setTimeout(() => {
            fn(...args);
            id = undefined;
        }, ms);
    };
}

export function optional(fn: (value) => unknown, predicate?: boolean) {
    function curried(predicate: boolean) {
        return (value) => (predicate ? value : fn(value));
    }
    return typeof predicate === 'boolean' ? curried(predicate) : curried;
}

export function omit(object: Record<string, unknown>, ...keys: string[]) {
    return Object.entries(object)
        .filter(([key]) => !keys.includes(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
}

export function classNames(classes: Record<string, boolean>): string {
    return Object.entries(classes).reduce(
        (string, [className, isOn]) => (isOn ? string.concat(' ' + className) : string),
        ''
    );
}

export function withDecimal(arg: string | number, fractionDigits = 2): string {
    if (arg === null) return '';
    const num = Number(arg);
    if (isNaN(num)) return '';
    return num.toFixed(fractionDigits);
}
