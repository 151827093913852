import {
    loadLocationById,
    loadLocations,
    createLocation,
    cancelLocation,
    authorizeLocation,
    updateLocation,
} from '../../apiClient';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorAction, successAction } from '../../utils/notifications';
import { EntityUpdateRequest } from '../../authorization/taskAuthorizations';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';
const actionTypes = {
    locationsLoaded: 'LocationsPage.dataLoaded',
    locationDetails: 'LocationDetailsPage.dataLoaded',
    locationLoadedNoContent: 'LocationDetailsPage.noContent',
    createLocation: 'LocationsPage.locationCreated',
    authorizeLocation: 'LocationsPage.locationAuthorized',
    cancelLocation: 'LocationsPage.locationCancelled',
    clearDetails: 'LocationDetailsPage.dataCleared',
};

const messages = {
    createSuccess: `Location was parked successfully.`,
    createError: `Location creation failed`,
    authorizeSuccess: `Location posted successfully.`,
    cancelSuccess: `Location cancelled successfully`,
    cancelError: `Location cancellation failed`,
    updateError: `Location update failed`,
    updateSuccess: `Location update request was parked successfully`,
    loadByIdError: `Location loading failed`,
};
export enum LocationStatus {
    parked = 'parked',
    posted = 'active',
    blocked = 'inactive',
    cancelled = 'cancelled',
}

export const ActionCreators = {
    loadLocations() {
        return async (dispatch) => {
            const locations = await loadLocations();
            dispatch({
                type: actionTypes.locationsLoaded,
                payload: locations.data,
            });
        };
    },
    loadLocationById(id: string) {
        return async (dispatch) => {
            try {
                const location = await loadLocationById(id);
                if (location.data) {
                    dispatch({
                        type: actionTypes.locationDetails,
                        payload: location.data,
                    });
                } else {
                    dispatch({ type: actionTypes.locationLoadedNoContent });
                }
            } catch (err) {
                dispatch(errorAction(messages.loadByIdError));
            }
        };
    },
    createLocation(data: { location1: string; location2: string }) {
        return async (dispatch) => {
            try {
                await createLocation(data);
                dispatch({
                    type: actionTypes.createLocation,
                });
                dispatch(successAction(messages.createSuccess));
            } catch (err) {
                dispatch(errorAction(messages.createError));
            }
        };
    },
    authorizeLocation(id) {
        return async (dispatch) => {
            await authorizeLocation(id);
            dispatch({
                type: actionTypes.authorizeLocation,
            });
            dispatch(successAction(messages.authorizeSuccess));
            const locations = await loadLocations();
            dispatch({
                type: actionTypes.locationsLoaded,
                payload: locations.data,
            });
        };
    },
    cancelLocation(id) {
        return async (dispatch) => {
            try {
                await cancelLocation(id);
                dispatch({
                    type: actionTypes.cancelLocation,
                });
                dispatch(successAction(messages.cancelSuccess));
                const locations = await loadLocations();
                dispatch({
                    type: actionTypes.locationsLoaded,
                    payload: locations.data,
                });
            } catch (err) {
                dispatch(errorAction(messages.cancelError));
            }
        };
    },
    updateLocation(location: Location) {
        return async (dispatch) => {
            try {
                await updateLocation(location);
                dispatch(routerHelperActions.makeRedirect('/beneficiaries/locations'));
                dispatch(successAction(messages.updateSuccess));
            } catch (err) {
                dispatch(errorAction(messages.updateError));
            }
        };
    },
    errorAction(message: string) {
        return (dispatch) => dispatch(errorAction(message));
    },

    clearDetails() {
        return (dispatch) => dispatch({ type: actionTypes.clearDetails });
    },
};

export function locationReducer(state = new LocationsState(), action) {
    switch (action.type) {
        case actionTypes.locationsLoaded:
            const list = action.payload.map((location) => new Location(location));
            return new LocationsState(list, state.details);
        case actionTypes.locationDetails:
            const details = new Location(action.payload);
            return new LocationsState(state.list, details);
        case actionTypes.clearDetails:
            return new LocationsState(state.list);
        default:
            return state;
    }
}

export const withLocation = connect(mapStateToProps, mapDispatchToProps as any);

export class LocationsState {
    list: Array<Location>;
    details: Location;
    constructor(list = [], details = new Location({})) {
        this.list = list;
        this.details = details;
    }
}
export class Location {
    id: number;
    location1: string;
    location2: string;
    status: string;
    createdAt: Date;
    createdByManager?: ManagerDTO;
    createdByManagerId?: number;
    updatedAt?: Date;
    authorizedAt?: Date;
    authorizedByManager?: ManagerDTO;
    cancelledAt?: Date;
    cancelledByManager?: ManagerDTO;
    pendingUpdates?: EntityUpdateRequest[];
    constructor(attrs) {
        this.id = attrs.id;
        this.location1 = attrs.location1;
        this.location2 = attrs.location2;
        this.status = attrs.status;
        this.createdAt = attrs.createdAt;
        this.createdByManager = attrs.createdByManager;
        this.updatedAt = attrs.updatedAt;
        this.authorizedAt = attrs.authorizedAt;
        this.authorizedByManager = attrs.authorizedByManager;
        this.cancelledAt = attrs.cancelledAt;
        this.cancelledByManager = attrs.cancelledByManager;
        this.createdByManagerId = attrs.createdByManagerId;
        this.pendingUpdates = attrs.pendingUpdates;
    }
}
class ManagerDTO {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    constructor(attrs) {
        this.id = attrs.id;
        this.firstName = attrs.firstName;
        this.lastName = attrs.lastName;
        this.email = attrs.email;
    }
}

function mapStateToProps(state: any) {
    return {
        locations: state.locations,
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

export interface LocationActionCreators {
    loadLocations(): (...args: any[]) => any;
    loadLocationById(id: string): (...args: any[]) => any;
    createLocation(body: { location1: string; location2: string }): (...args: any[]) => any;
    authorizeLocation(id: string): (...args: any[]) => any;
    cancelLocation(id: string): (...args: any[]) => any;
    updateLocation(location: Location): (...args: any[]) => any;
    errorAction(message: string): (...args: any[]) => any;
    clearDetails(): (...args: any[]) => any;
}
