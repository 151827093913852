import { AuthState, hasFeatureAccess } from '../login/auth';
import { ManagerPermission } from '../permission-profiles/permission';
import { AsyncTask, AsyncTaskStatus, AsyncTasksTypes } from './asyncTasks';

export const canCancelWholeImport = (auth: AuthState, row: AsyncTask, canBeSigned: boolean): boolean => {
    // is scope announcements or import beneficiaries
    if(row.type !== AsyncTasksTypes.SCOPE_ANNOUNCEMENTS && row.type !== AsyncTasksTypes.IMPORT_BENEFICIARIES) return false;

    // is not subtask
    if(row.parentId) return false;

    // is not cancelled
    if(row.cancelledAt) return false;

    // is preCancelled
    if(row.preCancelledAt) {
        if(!auth.manager) return false;
        if(row.preCancelledByManager.id === auth.manager.id) return false;
        if(!hasFeatureAccess(auth, ManagerPermission.beneficiariesEntitlementVerify)) return false;
    } else {
        if(row.status !== AsyncTaskStatus.toBeSigned) return false;
        // can be signed = can be canceled
        if(canBeSigned) return true;
        if(!hasFeatureAccess(auth, ManagerPermission.beneficiariesEntitlementPark)) return false;
    }

    return true;
};
