export class RefreshSignaler {
    private interval: number;
    private action: () => any;

    constructor(actionCreator: () => Promise<any>, timeout: number, errorHandler?: () => void) {
        this.action = () =>
            actionCreator().catch(() => {
                this.stop();
                return errorHandler();
            });
        this.interval = window.setInterval(this.action, timeout);
    }

    refresh() {
        this.action();
    }

    stop() {
        clearInterval(this.interval);
    }
}

let previousSignaler;

export function wrapActionCreator(actionCreator: () => Promise<any>, timeout = 8000): Promise<RefreshSignaler> {
    if (previousSignaler) {
        previousSignaler.stop();
    }

    return actionCreator().then(() => {
        previousSignaler = new RefreshSignaler(actionCreator, timeout);
        return previousSignaler;
    });
}

let healthCheckSignaler;

export function healthCheckRefresher(
    promisedAction: () => Promise<any>,
    errorHandler?: () => void
): Promise<RefreshSignaler> {
    if (healthCheckSignaler) {
        healthCheckSignaler.stop();
    }

    return promisedAction().then(() => {
        healthCheckSignaler = new RefreshSignaler(promisedAction, 30000, errorHandler);
        return healthCheckSignaler;
    });
}

export function stopHealthCheckRefresher() {
    if (healthCheckSignaler) {
        healthCheckSignaler.stop();
    }
}
